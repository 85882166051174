.resume {
    margin-left: .5em;
    font-weight: bold;
}

h1 {
    font-family: 'Inspiration', cursive;
    letter-spacing: 5px;
}

h2{
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px double black;
    border-width: 5px;
    padding: .5em;
    
}

.wrap {
    display: flex;
    /* flex-wrap: wrap; */
}

.margin {
    margin-right: 3.5em;
}

.resume-container {
    width: 100%;
    height: 100%;
    font-size: 18px;
}

.proficiencies {
margin: 0 2em 2em 2em;
padding: 0 2em 1em 1.5em;
}



.front-end {
    border: 10px inset var(--sapphire);
    border-radius: 5px;
}

/* .front-end:hover {
    background-color: #def3fe;
    color: white;
} */

.back-end {
    border: 10px inset var(--magenta);
    border-radius: 5px;
}

/* .back-end:hover {
    background-color:#f0c0cc;
} */

.soft-skills {
    border: 10px inset var(--rust);
    border-radius: 5px;
}

/* .soft-skills:hover {
    background-color: #fdceb8;
} */

@media screen and (max-width: 1352px) {
    .skills-container {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 852px) {
    .front-end-wrapper,
    .back-end-wrapper,
    .soft-skills-wrapper {
        min-width: 550px !important;
    }
}

@media screen and (max-width: 825px) {
    .resume {
        position: absolute;
        
    }
    .resume-container {
        display: flex;
        justify-content: center;
        position: relative;
    }

    .proficiencies:first-child {
        margin-top: 4em;
    }
}

@media screen and (max-width:530px) {
    .wrap {
        flex-direction: column;
    }

    .front-end-wrapper,
    .back-end-wrapper,
    .soft-skills-wrapper {
        min-width: 450px !important;
    }
}

@media screen and (max-width:433px) {
    .front-end-wrapper,
    .back-end-wrapper,
    .soft-skills-wrapper {
        min-width: 350px !important;
    }
 }

 @media screen and (max-width:336px) {
    .front-end-wrapper,
    .back-end-wrapper,
    .soft-skills-wrapper {
        min-width: 250px !important;
    }
  }
    
