.contactContainer {
    display: flex;
    justify-content: space-evenly;
}


@media screen and (max-width: 900px) {
    .contactContainer {
        display: flex;
        justify-content: center;
    }
}

