h1 {
    font-size: 3.5rem;
    text-align: center;
}

.formHeader {
    border-bottom: 3px double black;
    padding-bottom: .5em;
}


.contactForm {
    background-color: #f2f2f2;
    padding: 18px;
    margin: 3rem 5rem;
    border-radius: 5px;
    border: 10px inset var(--turquoise);
    min-width: 450 !important;
}

/* @media screen and (max-width: 500px) {
    .contactForm {
        min-width: 350;
    }
} */