.projectCard {
    margin: 5em;
    min-width: 400px;
}

.card-icon-container {
    display: flex;
    justify-content: center;
    align-content: flex-end;
    /* justify-content: flex-start; */
}

.cardHeader {
    background-color: var(--pale);
    color: white;
    text-shadow: 2px 2px #3b3b3b;
    /* font-family: 'Inspiration', cursive !important; */
    letter-spacing: .1em;
}

@media screen and (max-width: 430px) {
.projectCard {
        margin: 2em;
        min-width: 300px;
    }
}
