.navbar {
    background-color: var(--navbar);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .navBtnCont {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidden {
    display: none;
  }


/* --------------------*/
/* Logo                */
/* --------------------*/

.initials {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: 5px outset rgb(137, 136, 136);
    margin: 1.5rem 0 1.5rem 0;
    padding: 1rem 3rem;
    color: var(--light);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 65px;
    height: 50px;
    line-height: 50px;
    margin-left: 2rem;
  }
  
  .animation {
    animation-name: color-flow;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  /* .logo-rotation {
    animation-name: logo-rotation;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  } */
  
  @keyframes color-flow {
    0% {
      background-color: var(--magenta);
    }
    20% {
      background-color: var(--rust);
    }
    40% {
      background-color: var(--mustard);
    }
    80% {
      background-color: var(--sapphire);
    }
    100% {
      background-color: var(--eggplant);
    }
  }
  
  /* @keyframes logo-rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  

/* --------------------*/
/* Nav buttons         */
/* --------------------*/
  

  .navBtn {
    padding: 0 3em;
    list-style: none;
  }

  .navBtnLink {
    font-family: 'Inspiration', cursive;
    text-decoration: none;
    list-style: none;
    color: var(--navbar-highlight);
    font-size: 4rem;
    letter-spacing: 5px;
    background-image: linear-gradient(270deg, var(--navbar-highlight) 0%, var(--navbar-highlight) 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.1em;
    background-position: bottom;
    border-radius: 8px;
    transition: all 0.25s ease-in;
    padding: 0.2em;
  }

  .navBtnLink:hover {
    background-size: 100% 100%;
    color: var(--navbar);
    font-weight: bold;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }



   @media screen and (max-width: 900px) {
    .navBar {
        justify-content: center;
        align-items: center;
        padding: 0 0 10px 0;
        width: 100%;
    }

    .navBtnCont {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 0 20px 0;
        width: 100%;
    }

    .navBtnLink {
        font-size: 3rem;
        /* padding: 0.2em; */
        
        
    }

    .initials {
       display: none;
       /* display: inline-block;
         margin: 10px 0 10 50px; */
    }

    .name {
        display: block;
        font-family: 'Inspiration', cursive;
        font-weight: normal;
        font-size: 5rem;
        letter-spacing: 5px;
        width: 100%;
        margin: 0 0 25px 0;
        padding: 1rem;
        text-align: center;
        color: inherit;
        background-color: var(--navbar-highlight);
    }
 
    
   }

  @media screen and (max-width: 575px) {
    .navBtnLink {
      font-size: 2rem;
      padding: 0.1em;
    }
  }

  @media screen and (max-width: 480px) {
    .navbar,
    .navBtnCont {
      width: 100%;
    }
    
    .navBtnCont {
    flex-wrap: wrap;
    }

    /* .navBtnLink:nth-child(2) {
      margin-bottom: 2rem;
    } */
    
    }
  